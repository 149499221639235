<template>
  <div>404 NOT FOUND</div>
</template>

<script>
export default {
  name: "Error404"
}
</script>

<style scoped>

</style>